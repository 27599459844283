/* global jQuery _ Swiper:false */
(function () {
	"use strict";

	(function ($) {
		$.fn.scrollAndHighlight = function () {
			var navLinks = $(".js-q-sticky-nav");

			navLinks.click(function (e) {
				e.preventDefault();

				var target = $(this).attr("href");
				var targetElement = $(target);
				var offsetTop = targetElement.offset().top;

				$("html, body").animate(
					{
						scrollTop: offsetTop - 80,
					},
					1000
				);
			});

			function updateNavLinks() {
				var scrollPosition = $(window).scrollTop();

				navLinks.removeClass("active");

				navLinks.each(function () {
					var target = $(this).attr("href");
					var targetElement = $(target);

					if (targetElement.length) {
						var targetTop = targetElement.offset().top - 100;
						var targetBottom =
							targetTop + targetElement.outerHeight();

						if (
							scrollPosition >= targetTop &&
							scrollPosition < targetBottom
						) {
							$(this).addClass("active");
						}
					}
				});
			}

			var handleScroll = _.debounce(updateNavLinks, 40);

			$(window).scroll(function () {
				handleScroll();
			});

			// Highlight the link on page load
			updateNavLinks();
		};

		$(document).ready(function () {
			if ($(".js-q-sticky-nav").length > 0) {
				$(".js-q-sticky-nav").scrollAndHighlight();
			}
		});
	})(jQuery);

	/**
	 * global navigation megamenu
	 */

	(function ($) {
		"use strict";

		$(document).ready(function () {
			$(".js-global-menu").each(function () {
				// var position, leftPos = 0, width = 0, newLeft, newWidth, firstEl = true;
				var leftPos = 0,
					width = 0,
					newLeft,
					newWidth,
					firstEl = true;

				var menuNav = $(this),
					headerContainer = menuNav.parents("nav"),
					headerMeta = headerContainer[0].getBoundingClientRect(),
					menuContainer = menuNav.parents(".js-megamenu"),
					menuItem = menuNav.find(".menu-item");

				menuNav.append(
					"<span class='q-megamenu-underline item-underline'></span>"
				);
				var $magicLine = menuNav.find(".item-underline");

				$magicLine.width(width).css("left", leftPos);

				menuItem.each(function () {
					var thisMenu = $(this),
						menuContent = thisMenu.data("menu"),
						menuSub = thisMenu.parents(".js-megamenu").find(".megamenu-wrap ." + menuContent),
						menuContentWrapper = menuSub.parent(),
						menuMeta = thisMenu[0].getBoundingClientRect(),
						subMeta = menuSub[0].getBoundingClientRect(),
						subBg = thisMenu.parents(".js-megamenu").find(".megamenu-bg"),
						subCnt = thisMenu.parents(".js-megamenu").find(".megamenu-wrap");

					thisMenu.on("mouseenter", function () {
						if (menuContent === "platform") {
							subBg.css({
								"left": 0,
								"transform": "unset"
							});
						}
						else if (menuContent === "login" || menuContent === "contact") {
							subBg.css({
								"right": 0,
								"transform": "unset"
							});
						} else if (menuContent === "community") {
							subBg.css({
								"right": 195,
								"transform": "unset"
							});
						}
						else if (menuContent === "more") {
							subBg.css({
								"left": "50%",
								"transform": "translateX(-50%)"
							});
						}
						else if (menuContent === "trial") {
							subBg.css({
								"right": "0",
								"transform": "unset"
							});
						}
						else {
							subBg.css({
								"left": menuMeta.left - headerMeta.left + "px",
								"transform": "unset"
							});
						}
						subBg.css("width", subMeta.width + "px");
						subBg.css("height", subMeta.height + "px");

						//- Set sub menu style
						subCnt.css("opacity", 1);
						if (menuContent === "platform") {
							subCnt.css({
								"left": 0,
								"transform": "unset"
							});
						} else if (menuContent === "login" || menuContent === "contact") {
							subCnt.css({
								"right": 0,
								"transform": "unset"
							});
						} else if (menuContent === "community") {
							subCnt.css({
								"right": 195,
								"transform": "unset"
							});
						}
						else if (menuContent === "more") {
							subCnt.css({
								"left": "50%",
								"transform": "translateX(-50%)"
							});
						}
						else if (menuContent === "trial") {
							subCnt.css({
								"right": "0",
								"transform": "unset"
							});
						}
						else {
							subCnt.css({
								"left": menuMeta.left - headerMeta.left + "px",
								"transform": "unset"
							});
						}

						subCnt.css("width", subMeta.width + "px");
						subCnt.css("height", subMeta.height + "px");

						//- Set current sub menu style
						menuContentWrapper.css("opacity", 1);

						menuContainer.addClass("dropdown-active");
						setTimeout(function () {
							menuContainer.addClass("dropdown-active-ani");
						}, 200);

						$(".menu-item").removeClass("active");
						thisMenu.addClass("active");

						$(".megamenu-item").removeClass("active");
						menuContentWrapper.addClass("active");

						if (firstEl) {
							firstEl = false;
							$magicLine.css("left", thisMenu.position().left);
						}

						newLeft = thisMenu.position().left;
						newWidth = thisMenu.width();

						$magicLine.stop().animate({
							left: newLeft,
							width: newWidth,
						});
					});

					menuContainer.on("mouseleave", function () {
						$(".megamenu-item")
							.removeClass("active")
							.css("opacity", 0);

						menuContainer.removeClass("dropdown-active");
						menuContainer.removeClass("dropdown-active-ani");
						thisMenu.removeClass("active");
						firstEl = true;

						$magicLine.stop().animate({
							width: 0,
						});
					});
				});
			});
		}); /* document.ready */
	})(jQuery);

	/**
	 * mobile menu
	 */

	(function ($) {
		"use strict";

		$(document).ready(function () {
			var mobileMenu = $("#q-mobile-menu").slideMenu();

			$(".js-close-menu").on("click", function () {
				setTimeout(function () {
					mobileMenu.navigateTo(".topLevel");
				}, 300);
			});
		});
	})(jQuery);

	// Megamenu Apps Filter

	(function ($) {
		"use strict";

		$(document).ready(function () {
			var filterItem = $(".js-mm-filter"),
				gridContaineer = $(".js-mm-apps-grid"),
				platformAppLabel = $("#q_platformApp_label"),
				platformMenu = $(".megamenu-bg");

			if (filterItem.length) {
				$(".js-mm-filter").on("mouseenter", function () {
					var item = $(this);

					var itemCol = item.attr("data-col");
					var itemCat = $(".js-mm-app[data-cat='" + itemCol + "']");

					if (itemCol !== "all") {
						gridContaineer.removeClass("q-mm-apps-grid");
						$(".js-mm-app").addClass("hidden");

						itemCat.removeClass("hidden");
						$(".js-mm-col").removeClass("q-mm-col");
						itemCat.addClass("activeCol");

						platformAppLabel.addClass("hidden");

						platformMenu.addClass("showFiletredItem");

					} else if (!gridContaineer.hasClass("q-mm-apps-grid")) {
						gridContaineer.addClass("q-mm-apps-grid");
						$(".js-mm-col").addClass("q-mm-col");

						$(".js-mm-app").removeClass("hidden");
						$(".js-mm-app").removeClass("activeCol");
						platformAppLabel.removeClass("hidden");
						platformMenu.removeClass("showFiletredItem");
					}

					// enable active tab
					$(".js-mm-filter").not(item).removeClass("active");
					item.addClass("active");
				});

				$(".js-megamenu").on("mouseleave", function () {
					$(".js-mm-filter").removeClass("active");
					$(".js-mm-filter[data-col='all']").addClass("active");
					$(".js-mm-app").removeClass("hidden");
					$(".js-mm-col").addClass("q-mm-col");
					$(".js-mm-app").removeClass("activeCol");
					platformAppLabel.removeClass("hidden");
					platformMenu.removeClass("showFiletredItem");

					if (!gridContaineer.hasClass("q-mm-apps-grid")) {
						gridContaineer.addClass("q-mm-apps-grid");
					}
				});
			}
		});
	})(jQuery);

	// Accouncement carousel swiper configuration

	const haederSwiperContainer = document.querySelector(".js-q-announcements");
	var isLoop = false;

	if (haederSwiperContainer) {
		if (haederSwiperContainer.querySelectorAll(".swiper-slide").length > 1) {
			isLoop = true;
		}
		const HeaderSwiper = new Swiper(".js-q-announcements", {
			direction: "vertical",
			loop: isLoop,
			autoplay: {
				delay: 10000,
				disableOnInteraction: false, // This prevents autoplay from stopping when the user interacts with the swiper
			},
			pagination: false,
			on: {
				afterInit: function () {
					haederSwiperContainer.classList.remove("q-topbar-opacity-0");
				},
			},
		});

		// Event handler for mouseenter (mouseover)
		HeaderSwiper.el.addEventListener("mouseenter", () => {
			HeaderSwiper.autoplay.stop(); // Pause autoplay when the mouse enters the Swiper container
		});

		// Event handler for mouseleave (mouseout)
		HeaderSwiper.el.addEventListener("mouseleave", () => {
			HeaderSwiper.autoplay.start(); // Resume autoplay when the mouse leaves the Swiper container
		});
	}

})();
